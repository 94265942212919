:root {
  --primary-color: Black;
  --secondary-color: #007bff;
  --background-color: #f7f8fa;
  --font-family: 'Montserrat', sans-serif;
  --card-shadow: rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--background-color);
}

.professionals-container {
  width: 1400px;
  padding: 20px;
  min-height: 100vh; /* Ocupará toda la altura de la ventana */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
}

.professionals-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filters-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-button {
  padding: 10px 15px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.filter-button:hover {
  background-color: var(--secondary-color);
}

.professionals-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center; /* Centra las tarjetas horizontalmente */
}

.professional-card-link {
  text-decoration: none;
  color: inherit;
  width: 100%;
  max-width: 1000px;
}

.professional-card {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 4px 8px var(--card-shadow);
  padding: 20px;
  width: 100%;
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.professional-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px var(--card-shadow);
}

.professional-image-container {
  flex-shrink: 0;
  margin-right: 20px;
}

.professional-image {
  width: 100px; /* Asegúrate de que esta anchura coincida con la especificada en la compresión */
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.professional-info {
  flex: 1;
}

.professional-name {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin: 0 0 10px 0;
}

.professional-rating {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--primary-color);
  margin: 5px 0;
}

.rating-score {
  margin-left: 10px;
  font-size: 14px;
  color: #555;
}

.professional-description {
  font-size: 14px;
  color: #555;
  margin: 10px 0;
}

.professional-bio {
  font-size: 14px;
  color: #555;
  margin: 10px 0;
}

.professional-price {
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-color);
  margin: 10px 0;
}

.professional-min-reservation {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  margin: 10px 0;
}

.money-icon {
  margin-right: 5px;
}

.professional-calendar {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
}

.calendar-icon {
  margin-right: 5px;
}

.professional-repeat {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  margin-top: 10px;
}

.repeat-icon {
  margin-right: 5px;
}

/* Nueva clase para el título */
.professionals-title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
  color: Black;
}

/* Actualiza el contenedor para que sea responsivo */
.professionals-container {
  width: 100%;
  max-width: 1400px;
  padding: 20px;
  min-height: 100vh; /* Ocupará toda la altura de la ventana */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
}


/* Para tablet (de 768px a 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .professional-list-service {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-items: center;
  }
}