/* Variables globales */
:root {
  --font-family: 'Montserrat', sans-serif;
  --main-color: #4A90E2;
  --secondary-color: #F5F5F5;
  --highlight-color: #FFD700;
  --text-color: #333;
  --secondary-text-color: #666;
  --muted-text-color: #999;
  --border-radius: 8px;
  --spacing: 16px;
  --transition: all 0.3s ease;
}

/* Contenedor principal */
.professional-detail-reviews-list {
  display: grid;
  gap: var(--spacing);
  padding: var(--spacing);
  background-color: var(--secondary-color);
  border-radius: var(--border-radius);
  list-style: none;
  margin: 0;
}

/* Elementos individuales de la lista */
.professional-detail-review-item {
  padding: var(--spacing);
  background-color: #fff;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  gap: 12px;
  transition: var(--transition);
}

.professional-detail-review-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.review-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: var(--main-color);
}

.review-user-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Títulos y textos */
h3 {
  font-family: var(--font-family);
  color: var(--main-color);
  font-size: 1.5rem;
  margin-bottom: var(--spacing);
}

p {
  font-family: var(--font-family);
  color: var(--text-color);
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}

p:empty {
  color: var(--muted-text-color);
  font-style: italic;
}

.comment {
  font-style: italic;
  color: #555;
}

.ratings-summary {
  font-family: var(--font-family);
  color: #000; /* Tipografía negra */
  padding: var(--spacing);
  border-radius: var(--border-radius);
  text-align: left; /* Desplaza el contenido hacia la izquierda */
  transition: var(--transition);
}

/* puntaje principal */

.overall-rating {
  margin-bottom: var(--spacing);
}

/* puntaje principal */
.rating-value {
  font-size: 2.8rem;
  font-weight: 600;
  color: var(--highlight-color);
  display: flex;
  align-items: center;
  gap: 8px;
  color: black;;
}

/* que puntaje tiene */
.rating-value .star {
  font-size: 1.6rem;
}

/* numero de reviews */
.total-reviews {
  font-size: 1rem;
  color: #000; /* Tipografía negra */
  margin-top: 4px;
}

.rating-description {
  font-size: 1.2rem;
  color: #000; /* Tipografía negra */
  margin-top: 8px;
}


.categories {
  margin-bottom: var(--spacing);
}

.category {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.category-name {
  flex: 1;
  font-size: 1em;
}

.progress-bar {
  flex: 2;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  margin: 0 8px;
  overflow: hidden;
  position: relative;
}

.progress {
  height: 100%;
  background-color: var(--highlight-color);
  transition: width 0.4s ease;
}

.category-rating {
  font-size: 1em;
  color: var(--secondary-text-color);
}

/* Comments */
.comments {
  margin-top: var(--spacing);
}

.comment {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--spacing);
}

.user-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: var(--spacing);
  transition: var(--transition);
}

.comment-content {
  flex: 1;
}

.comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.user-name {
  font-weight: bold;
  margin-right: 8px;
}

.verified-icon {
  color: var(--main-color);
  margin-right: 8px;
}

.comment-date {
  font-size: 0.9em;
  color: var(--muted-text-color);
}

.comment-text {
  font-size: 1em;
  color: var(--text-color);
}

@media (min-width: 768px) {
  .professional-detail-reviews-list {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .professional-detail-review-item {
    grid-template-columns: 1fr 1fr;
  }

  p {
    font-size: 1.1rem;
  }
}
