.ratings-summary {
    display: grid;
    grid-template-columns: repeat(12, 1fr); /* 12 columnas */
    gap: var(--spacing);
  }
  
  .overall-rating {
    grid-column: span 3; /* Desktop: 4 columnas */
  }
  
  .categories {
    grid-column: span 9; /* Desktop: 8 columnas */
  }
  
  .comments {
    grid-column: span 12; /* Siempre ocupa las 12 columnas */
  }
  
  
  /* Ajustes para pantallas medianas */
  @media (max-width: 1024px) {
    .overall-rating {
        grid-column: span 12; /* Ocupa las 12 columnas completas */
        display: grid;
        grid-template-columns: repeat(6fr); /* Subgrid de 12 columnas */
        gap: 8px;
        align-items: center;
      }
      
      /* Rating row (puntuación y descripción) */
      .rating-row {
        grid-column: span 12; /* Ocupa todo el ancho */
        display: grid;
        grid-template-columns: 4fr 8fr; /* 4 columnas para el valor, 8 para la descripción */
        align-items: center;
        gap: 8px;
      }
      
      /* Rating value (valor numérico) */
      .rating-value {
        font-size: 2.5rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        gap: 4px;
      }
      
      /* Star next to rating */
      .rating-value .star {
        font-size: 1.5rem;
      }
      
      /* Rating description (descripción textual) */
      .rating-description {
        font-size: 1.2rem;
        color: var(--secondary-text-color);
      }
      
      /* Total reviews (valoraciones totales) */
      .total-reviews {
        grid-column: span 12; /* Ocupa todo el ancho en una nueva fila */
        font-size: 1rem;
        color: var(--muted-text-color);
        margin-top: 4px;
      }
  
  
      .categories {
      grid-column: span 12; /* Tablet: 6 columnas */
    }
    
  }
  
  /* Ajustes para pantallas pequeñas */
  @media (max-width: 768px) {
    .overall-rating,
    .categories,
    .comments {
      grid-column: span 12; /* Mobile: 12 columnas */
    }
  }
  