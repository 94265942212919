/* ProfessionalProfile.css */

/* Contenedor Principal */
.profile-container {
  display: grid;
  gap: 20px;
  padding: 20px;
  max-width: 1500px; /* Limita el ancho máximo del contenedor */
  margin: 0 auto; /* Centra el contenedor */
  grid-template-columns: repeat(12, 1fr); /* Divide el contenedor en 12 columnas */
}

/* Secciones con Distribución Ajustada */
.profile-summary {
  grid-column: span 4; /* Ocupa 4 columnas */
}

.profile-details {
  grid-column: span 8; /* Ocupa 8 columnas */
}

.gallery {
  grid-column: span 4; /* Ocupa todo el ancho */
}

.reviews {
  grid-column: span 8; /* Ocupa todo el ancho */
}

.comments {
  grid-column: span 12; /* Ocupa todo el ancho */
}

/* Media Queries */
@media (max-width: 1024px) {
  /* Ajuste para pantallas medianas */
  .profile-summary {
    grid-column: span 6; /* Ocupa media pantalla */
  }

  .profile-details {
    grid-column: span 6; /* Ocupa media pantalla */
  }

  .gallery {
    grid-column: span 12; /* Ocupa todo el ancho */
  }

  .reviews {
    grid-column: span 12; /* Ocupa todo el ancho */
  }

  .comments {
    grid-column: span 12; /* Ocupa todo el ancho */
  }
}

@media (max-width: 768px) {
  /* Ajuste para pantallas pequeñas */
  .profile-summary,
  .profile-details,
  .gallery,
  .reviews,
  .comments {
    grid-column: span 12; /* Cada elemento ocupa todo el ancho */
  }
}

@media (max-width: 480px) {
  /* Ajuste para pantallas extra pequeñas */
  .profile-container {
    gap: 10px;
    padding: 10px;
  }

  .profile-summary,
  .profile-details,
  .gallery,
  .reviews,
  .comments {
    grid-column: span 12; /* Cada elemento ocupa todo el ancho */
  }
}
/* ProfessionalProfile.css */

/* Contenedor Principal */
.profile-container {
  display: grid;
  gap: 20px;
  padding: 20px;
  max-width: 1500px; /* Limita el ancho máximo del contenedor */
  margin: 0 auto; /* Centra el contenedor */
  grid-template-columns: repeat(12, 1fr); /* Divide el contenedor en 12 columnas */
}

/* Secciones con Distribución Ajustada */
.profile-summary {
  grid-column: span 4; /* Ocupa 4 columnas */
}

.profile-details {
  grid-column: span 8; /* Ocupa 8 columnas */
}

.gallery {
  grid-column: span 4; /* Ocupa 4 columnas */
}

.reviews {
  grid-column: span 8; /* Ocupa 8 columnas */
}

.comments {
  grid-column: span 12; /* Ocupa 12 columnas */
}

.chat-section {
  grid-column: span 12; /* Ocupa 12 columnas */
}


/* Media Queries */
@media (max-width: 1024px) {
  /* Ajuste para pantallas medianas */
  .profile-summary {
    grid-column: span 6; /* Ocupa media pantalla */
  }

  .profile-details {
    grid-column: span 6; /* Ocupa media pantalla */
  }

  .gallery {
    grid-column: span 12; /* Ocupa todo el ancho */
  }

  .reviews {
    grid-column: span 12; /* Ocupa todo el ancho */
  }

  .comments {
    grid-column: span 12; /* Ocupa todo el ancho */
  }

  .chat-section {
    grid-column: span 12; /* Ocupa todo el ancho */
  }
}

@media (max-width: 768px) {
  /* Ajuste para pantallas pequeñas */
  .profile-summary,
  .profile-details,
  .gallery,
  .reviews,
  .comments,
  .chat-section {
    grid-column: span 12; /* Cada elemento ocupa todo el ancho */
  }
}

@media (max-width: 480px) {
  /* Ajuste para pantallas extra pequeñas */
  .profile-container {
    gap: 10px;
    padding: 10px;
  }

  .profile-summary,
  .profile-details,
  .gallery,
  .reviews,
  .comments,
  .chat-section {
    grid-column: span 12; /* Cada elemento ocupa todo el ancho */
  }
}