/* src/professionalprofilescript/Reviews.css */

/* Contenedor Principal de Reseñas */
.reviews-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Espacio entre las tarjetas */
  justify-content: center; /* Centra las tarjetas horizontalmente */
  padding: 20px;
  background-color: #f9f9f9; /* Fondo para separar visualmente la sección */
  border-radius: 10px; /* Suaviza los bordes del contenedor */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Tarjetas de Reseña */
.review-card {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: calc(33.333% - 20px); /* Ocupa un tercio del ancho del contenedor */
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.review-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Botón Mostrar Más */
.show-more-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 12px 20px;
  margin-top: 15px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s ease, transform 0.2s ease;
  display: block; /* Asegura que el botón ocupe toda la fila */
}

.show-more-button:hover {
  background: #0056b3;
  transform: translateY(-3px);
}

/* Responsividad */
@media (max-width: 1024px) {
  .review-card {
    width: calc(50% - 20px); /* Cambia a dos columnas en pantallas medianas */
  }
}

@media (max-width: 768px) {
  .review-card {
    width: 100%; /* Ocupa toda la fila en pantallas pequeñas */
  }

  .show-more-button {
    font-size: 12px;
    padding: 10px 15px;
  }
}
