.edit-profile {
    max-width: 800px;
    margin: 30px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .edit-profile h1 {
    text-align: center;
    font-size: 32px;
    color: #333;
    margin-bottom: 25px;
  }
  
  .display-name-container,
  .personal-data-container {
    margin-bottom: 20px;
  }