/* src/Footer.css */

.footer {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
}

.footer-content {
  margin-top: 20px;
}

.footer-content h2 {
  margin-bottom: 10px;
}

.footer-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content input {
  margin-bottom: 10px;
  padding: 10px;
  width: 200px;
}

.footer-content button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.footer-content button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .footer {
    display: none;
  }
}