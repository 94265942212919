/* Importa la fuente desde Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

/* Aplica la fuente a todo el documento */
body {
  font-family: 'Montserrat', sans-serif;
}

/* Aplica la fuente a los elementos específicos del header */
.header-custom-nav,
.header-profile-menu,
.header-profile-name,
.header-custom-nav-item a,
.header-mobile-nav-item a {
  font-family: 'Montserrat', sans-serif;
}

/* Estilo para la barra de navegación */
.header-custom-nav {
  display: flex;
  justify-content: center; /* Centra los elementos horizontalmente */
  align-items: center; /* Alinea los elementos verticalmente */
  max-width: auto;
  margin: 0 auto;
  height: 120px; /* Ajusta la altura a 50px */
  padding: 10px 20px; /* Ajusta el padding para mantener el contenido centrado */
  width: 100%;
  background-color: #ffffff; /* Fondo blanco */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  border-radius: 50px;
  transition: transform 0.3s ease, opacity 0.3s ease; /* Transición para el desplazamiento */
}

.header-mobile-nav {
  display: none; /* Menú de escritorio oculto en móviles */
}

.header-logo-container {
  flex-shrink: 0;
  margin-right: 30px; /* Espacio entre el logo y el resto de los elementos */
}

.header-logo {
  width: 100px; /* Ajusta el tamaño del logo si es necesario */
  height: auto;
}
.header-custom-nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center; /* Centra los elementos de la lista */
}


.header-mobile-nav {
  display: none; /* Menú de escritorio oculto en móviles */
}

.header-logo-container {
  flex-shrink: 0;
  margin-right: 30px; /* Espacio entre el logo y el resto de los elementos */
}

.header-logo {
  width: 150px;
  height: auto;
}

.header-custom-nav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center; /* Centra los elementos de la lista */
}

.header-custom-nav-item {
  margin: 0 15px;
}

.header-custom-nav-item a {
  text-decoration: none;
  color: #000; /* Cambié el color de la tipografía a negro */
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s ease;
}

.header-custom-nav-item a:hover {
  color: #0071E3;
}

.header-profile-name {
  font-size: 18px;
  margin-bottom: 10px;
  align-self: center; /* Alinea el nombre verticalmente */
  font-weight: 700; /* Pone el nombre del usuario en negrilla */
}

.header-profile-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-left: 0px; /* Espacio entre el avatar y los enlaces */
}

.header-profile-container:hover .header-profile-menu {
  opacity: 1;
  visibility: visible;
}


.header-profile-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #000;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: flex;
  flex-direction: column; /* Alinea los elementos en columna */
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Añade transición para mostrar/ocultar */
  opacity: 0;
  visibility: hidden;
}

.header-profile-menu::before {
  content: '';
  position: absolute;
  top: -10px;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #000; /* Color del fondo del menú */
}

.header-profile-container:hover .header-profile-menu {
  opacity: 1;
  visibility: visible;
}

.header-profile-menu a,
.header-profile-menu button {
  color: #fff;
  text-decoration: none;
  background: none;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  transition: color 0.3s ease; /* Añade transición para el color */
}

.header-profile-menu a:hover,
.header-profile-menu button:hover {
  color: #0071E3; /* Cambia el color del texto al pasar el cursor */
}

.header-calendar-icon {
  font-size: 20px;
  color: #000; /* Cambia el color del icono a negro */
  transition: color 0.3s ease; /* Añade transición para el color */
}

.header-calendar-icon:hover {
  color: #0071E3; /* Cambia el color a azul al hacer hover */
}

.header-profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover; /* Asegura que la imagen tenga estilo cover */
}

.header-calendar-icon {
  font-size: 20px;
  color: #000; /* Cambia el color del icono a negro */
  transition: color 0.3s ease; /* Añade transición para el color */
}

.header-calendar-icon:hover {
  color: #0071E3; /* Cambia el color a azul al hacer hover */
}

.header-profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: -10px; /* Sube la imagen un poco más */
  object-fit: cover; /* Asegura que la imagen tenga estilo cover */
}

.header-custom-nav-item .header-profile-container {
  position: relative;
}

/* Para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
  .header-mobile-nav {
    display: block;
    position: fixed; /* Fija el menú en la parte inferior de la pantalla */
    bottom: 0; /* Coloca el menú en la parte inferior */
    left: 0; /* Alinea el menú al borde izquierdo */
    width: 100%; /* Asegura que ocupe todo el ancho de la pantalla */
    background-color: #fff; /* Fondo blanco */
    padding: 15px 0px 30px 0px; /* Padding vertical para el menú */
    z-index: 1000; /* Asegura que el menú esté encima de otros elementos */
    box-sizing: border-box; /* Asegura que el padding no afecte el ancho */
    border-top: 1px solid #ddd; /* Línea de separación sutil */
  }

  .header-custom-nav {
    display: none; /* Menú de escritorio oculto en móviles */
  }

  .header-mobile-nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around; /* Distribuye los elementos de manera uniforme */
    align-items: center; /* Centra los elementos verticalmente */
    width: 100%; /* Asegura que ocupe todo el ancho de la pantalla */
  }

  .header-mobile-nav-item {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%; /* Hace que cada item ocupe un 20% del ancho */
    padding: 5px;
  }

  .header-mobile-nav-item a {
    text-decoration: none;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px; /* Ajuste de tamaño para el texto */
    justify-content: center;
  }

  .header-mobile-nav-item a svg {
    font-size: 24px; /* Ajusta el tamaño de los iconos */
    margin-bottom: 5px; /* Espacio entre el icono y el texto */
  }

  .header-mobile-nav-item a:hover {
    color: #0071E3; /* Color de texto al hacer hover */
  }
}
