.professionallistuser-container {
  padding: 10px;
}

.professionallistuser-header {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.professionallistuser-list {
  list-style: none;
  padding: 0;
}

.professionallistuser-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  position: relative; /* Para posicionar el badge */
}

.professionallistuser-item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.text-container {
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: bold;
}

.last-message {
  color: #888;
  font-size: 0.9rem;
}

.unread-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8rem;
  position: absolute; /* Posiciona el badge */
  top: 10px; /* Ajusta según sea necesario */
  right: 10px; /* Ajusta según sea necesario */
}