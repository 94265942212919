/* Contenedor Principal del Perfil Profesional */
.profile-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  height: auto;
}

/* Contenedor del botón de enviar mensaje */
.professional-detail-message-chat-section {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: flex-start; /* Alinea el botón a la izquierda */
}

/* Botón de enviar mensaje */
.professional-detail-message-start-chat-button {
  display: inline-flex;
  align-items: center;
  gap: 8px; /* Espacio entre el ícono y el texto */
  padding: 10px 20px;
  background-color: #09f;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para dar profundidad */
}

/* Hover del botón */
.professional-detail-message-start-chat-button:hover {
  background-color: #007acc;
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada */
}

/* Ícono dentro del botón */
.professional-detail-message-icon {
  font-size: 1.2rem; /* Tamaño del ícono */
  color: #fff; /* El mismo color que el texto del botón */
}


.profile-verified {
  color: #09f; /* Azul similar al de redes sociales */
  font-size: 1.2rem;
  margin-left: 5px;
}

/* Contenedor de la Imagen del Perfil */
.profile-image-container {
  grid-column: span 3;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

/* Imagen de Perfil */
.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Información Personal */
.profile-info {
  grid-column: span 8;
  display: grid;
  font-size: 1rem;
  color: #555;
}

.profile-name {
  font-size: 1.8rem;
  font-weight: bold;
  color: #0052aa;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.profile-verified {
  color: #09f;
  font-size: 1.2rem;
}

.profile-rating {
  font-size: 1.2rem;
  color: #555;
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.profile-reviews {
  font-size: 1rem;
  color: rgb(0, 0, 0);
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
}

/* Biografía del Profesional */
.profile-biography {
  grid-column: span 10;
  text-align: left;
  font-size: 14px;
  margin-bottom: 20px;
  color: #555;
  font-weight: 500;
  line-height: 1.2;
}

.biography-title {
  text-align: left;
  font-size: 24px;
  margin-bottom: 20px;
  color: #555;
  font-weight: 600;
}

/* Estilo del Enlace de Reserva */
.profile-reservation {
  grid-column: span 12;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-reservation-link {
  padding: 12px 24px;
  background-color: #09f;
  color: white;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.profile-reservation-link:hover {
  background-color: #007acc;
  transform: scale(1.05);
}

/* Botón de reservar ahora */
.mobile-reservation-button {
  display: inline-flex;
  align-items: center;
  gap: 8px; /* Espacio entre el ícono y el texto */
  padding: 10px 20px;
  background-color: #09f;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para dar profundidad */
}

/* Hover del botón de reservar ahora */
.mobile-reservation-button:hover {
  background-color: #007acc;
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada */
}

/* Ícono dentro del botón de reservar ahora */
.mobile-reservation-icon {
  font-size: 1.2rem; /* Tamaño del ícono */
  color: #fff; /* El mismo color que el texto del botón */
}



/* Media Queries */
@media (max-width: 1024px) {
  .profile-container {
    grid-template-columns: repeat(6, 1fr);
    gap: 15px;
    padding: 15px;
  }

  .profile-image-container {
    grid-column: span 6;
    justify-content: flex-start;
  }

  .profile-image {
    width: 120px;
    height: 120px;
  }

  .profile-info {
    grid-column: span 6;
    font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  


  .profile-container {
    grid-template-columns: repeat(12, 1fr);
    gap: 5px;
    padding: 10px;
  }

  .profile-image-container,
  .profile-info,
  .profile-biography,
  .profile-reservation {
    grid-column: span 12;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }

  .profile-info {
    font-size: 0.9rem;
  }

  .profile-reservation-link {
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {

  
  .profile-container {
    grid-template-columns: repeat(12, 1fr);
    gap: 5px;
    padding: 8px;
    width: auto;
  }

  .profile-image-container,
  .profile-info,
  .profile-biography,
  .profile-reservation {
    grid-column: span 12;
  }

  .profile-image {
    width: 80px;
    height: 80px;
  }

  .profile-info {
    font-size: 0.85rem;
  }

  .profile-reservation-link {
    padding: 8px 16px;
  }
  /* Mover el formulario hacia la izquierda */
.mobile-reservation-form {
  margin-left: -10px; /* Ajusta este valor para mover hacia la izquierda */
}
}
