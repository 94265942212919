/* src/professionalprofilescript/Comments.css */

/* Contenedor Principal de Comentarios */
.muestra-profesional-comentarios {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Espacio entre los comentarios */
  padding: 20px;
  background-color: #f9f9f9; /* Fondo para separar visualmente la sección */
  border-radius: 10px; /* Suaviza los bordes del contenedor */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Comentario Individual */
.comment {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.comment:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Responsividad */
@media (max-width: 768px) {
  .muestra-profesional-comentarios {
    padding: 15px;
  }

  .comment {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .muestra-profesional-comentarios {
    padding: 10px;
  }

  .comment {
    padding: 8px;
  }
}