/* src/ReservationForm/Titulohome.css */

.title {
  color: #FFF;
  font-family: Montserrat, sans-serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 97%; /* 97px */
  letter-spacing: -4px;
  text-align: left; /* Alinea el texto a la izquierda */
  margin-top: 200px; /* Aumenta este valor para bajar el título */
}

.changing-word {
  color: #0071E3; /* Puedes ajustar el color si lo deseas */
  animation: fadeInOut 1s infinite;
}
