@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.locations-container {
    text-align: center;
    max-width: 1400px; /* Limita el ancho máximo del contenedor */
    margin: 0 auto; /* Centra el contenedor */
}

.locations-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #2E2929; /* Cambia el color del título a negro */
    font-family: 'Montserrat', sans-serif; /* Aplica la fuente Montserrat */
    font-weight: bold; /* Aplica negrita */
    line-height: 1.5; /* Ajusta el interlineado */
    text-transform: uppercase; /* Convierte el texto a mayúsculas */
    letter-spacing: -1px; /* Ajusta la separación de las letras */
  }

.locations-grid {
    display: flex;
    justify-content: center; /* Centra los elementos horizontalmente */
    align-items: flex-start; /* Asegura que todos estén alineados arriba */
    gap: 50px; /* Espaciado entre elementos */
    width: 100%;
    flex-wrap: wrap; /* Permite que los elementos se ajusten en varias líneas si es necesario */
}

.location-card {
    cursor: pointer;
    transition: all 0.3s;
    text-align: left; /* Alinea el texto a la izquierda */
    font-family: 'Montserrat', sans-serif;
    font-weight: 400; /* Regular */
    width: 300px; /* Ancho de cada contenedor */
    position: relative;
    min-height: 100px; /* Mantiene una altura mínima para evitar movimiento */
}

.location-card h4 {
    color: #2E2929;
    font-size: 36px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between; /* Asegura que el "+" o "-" esté alineado a la derecha */
    align-items: center;
    width: 100%;
}

.location-card .categories {
    margin-top: 10px;
    min-height: 30px; /* Ajusta el espacio para evitar movimientos */
}

.location-card h4.bold {
    font-weight: 700; /* Negrita cuando está expandido */
}

.categories {
    margin-top: 10px;
}

.category-item {
    font-size: 25px; /* Tamaño de la tipografía de "Limpieza" */
    padding: 5px 0;
}
