/* Importa la fuente Montserrat desde Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

.howitworks-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  max-width: 1400px; /* Limita el ancho máximo del contenedor */
  margin: 0 auto; /* Centra el contenedor */
}

.howitworks-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #000; /* Cambia el color del título a negro */
  font-family: 'Montserrat', sans-serif; /* Aplica la fuente Montserrat */
  font-weight: bold; /* Aplica negrita */
  line-height: 1.5; /* Ajusta el interlineado */
  text-transform: uppercase; /* Convierte el texto a mayúsculas */
  letter-spacing: -1px; /* Ajusta la separación de las letras */
}

.howitworks-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Distribuye las tarjetas dinámicamente */
  gap: 1.5rem;
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho */
}

.step {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  max-width: 300px;
  margin: 0 auto; /* Centra cada paso dentro de su celda */
  transition: transform 0.3s ease;
  font-family: 'Montserrat', sans-serif; /* Aplica la fuente Montserrat */
  font-weight: bold; /* Aplica negrita */
  line-height: 1.5; /* Ajusta el interlineado */
  text-transform: capitalize; /* Convierte la primera letra de cada palabra a mayúscula */
  letter-spacing: 0px; /* Ajusta la separación de las letras */

}


.step h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #000; /* Cambia el color del texto a negro */
  letter-spacing: 0px; /* Ajusta la separación de las letras */
}

.step p {
  font-size: 1rem;
  color: #666;
}

.step img {
  width: 100px; /* Ajusta el tamaño del vector según sea necesario */
  margin-bottom: 10px;
}

.step:hover {
  transform: scale(1.010);
}

/* Estilos específicos para dispositivos móviles */
@media (max-width: 768px) {
  .howitworks-steps {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .step {
    flex: 0 0 80%; /* Ajusta el tamaño de cada tarjeta en el carrusel */
    scroll-snap-align: center;
    margin: 0 10px; /* Espacio entre las tarjetas */
  }
}