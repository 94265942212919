.message-list {
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
}

.message-item {
  max-width: 60%;
  margin: 5px 0;
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.message-item.professional {
  align-self: flex-end;
  background-color: #dcf8c6;
  text-align: right;
}

.message-item.user {
  align-self: flex-start;
  background-color: #fff;
  text-align: left;
}

.message-content {
  margin-bottom: 5px;
}

.message-actions {
  display: flex;
  justify-content: space-between;
}

.message-actions button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007bff;
}