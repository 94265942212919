/* ProfileDetailProfesional.css */

/* Contenedor Principal */
.muestra-profesional-detalles {
  display: grid;
  gap: 20px;
  padding: 20px;
  max-width: 1500px; /* Limita el ancho máximo del contenedor */
  margin: 0 auto; /* Centra el contenedor */
  grid-template-columns: repeat(12, 1fr); /* Divide el contenedor en 12 columnas */
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Secciones con Distribución Ajustada */
.sobre-mi-informacion {
  grid-column: span 12; /* Ocupa 4 columnas */
}

.precio-hora-informacion {
  grid-column: span 4; /* Ocupa 4 columnas */
}

.checkbox-informacion {
  grid-column: span 8; /* Ocupa 4 columnas */
}

.sobre-mi-informacion h3,
.precio-hora-informacion h3,
.checkbox-informacion h3 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.sobre-mi-informacion textarea,
.precio-hora-informacion input,
.checkbox-informacion input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.sobre-mi-informacion textarea {
  resize: vertical;
}

.muestra-profesional-tareas {
  margin-bottom: 20px;
}

.muestra-profesional-tareas label {
  display: block;
  font-size: 1rem;
  color: #333;
  margin-bottom: 8px;
}

.muestra-profesional-tareas input {
  margin-right: 10px;
}

.sobre-mi-informacion input[readonly],
.precio-hora-informacion input[readonly],
.sobre-mi-informacion textarea[readonly] {
  background-color: #e9ecef;
  cursor: not-allowed;
}

/* Media Queries para ajustar el diseño en pantallas más pequeñas */
@media (max-width: 1024px) {
  /* Ajuste para pantallas medianas */
  .sobre-mi-informacion,
  .precio-hora-informacion,
  .checkbox-informacion {
    grid-column: span 6; /* Ocupa media pantalla */
  }
}

@media (max-width: 768px) {
  /* Ajuste para pantallas pequeñas */
  .muestra-profesional-detalles {
    grid-template-columns: 1fr; /* Una columna en pantallas pequeñas */
  }

  .sobre-mi-informacion,
  .precio-hora-informacion,
  .checkbox-informacion {
    grid-column: span 12; /* Ocupa todo el ancho */
  }
}

@media (max-width: 480px) {
  /* Ajuste para pantallas extra pequeñas */
  .muestra-profesional-detalles {
    gap: 10px;
    padding: 10px;
  }

  .sobre-mi-informacion,
  .precio-hora-informacion,
  .checkbox-informacion {
    grid-column: span 12; /* Ocupa todo el ancho */
  }
}