/* DetailReservaGallery.css */

.detail-reserva-gallery {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .detail-reserva-gallery h3 {
    text-align: left; /* Alinea el texto a la izquierda */
    font-size: 24px;
    margin-bottom: 20px;
    color: #000000; /* Cambia el color del texto */
    font-weight: 600;
    margin-left: 0;
    letter-spacing: -1px; /* Junta un poco las letras */
    line-height: 1.2; /* Ajusta el espacio entre líneas si es necesario */
  }
  
  
  
  .gallery-images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    gap: 15px;
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .gallery-image:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive: Mobile Carousel */
  @media (max-width: 768px) {
    .gallery-images {
      display: flex;
      overflow-x: auto;
      gap: 10px;
      scroll-snap-type: x mandatory;
      padding-bottom: 10px;
    }
  
    .gallery-image {
      flex: 0 0 auto;
      scroll-snap-align: center;
      border-radius: 10px;
    }
  
    .gallery-images::-webkit-scrollbar {
      display: none;
    }
  
    .gallery-images {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
  
 /* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .modal.active {
    opacity: 1;
    visibility: visible;
  }
  
  .modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    background: #fff;
    border-radius: 12px;
    overflow: hidden; /* Asegura que la imagen no se desborde */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-image {
    width: 100%;
    height: auto;
    max-height: 100%; /* Asegura que la imagen no exceda la altura del modal */
    object-fit: contain; /* Ajusta la imagen sin recortarla */
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    border: none;
    border-radius: 50%;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 1.2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background 0.3s ease;
  }
  
  .modal-close:hover {
    background: #f0f0f0;
  }
  
  