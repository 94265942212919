@media (max-width: 768px) {
    .chat-container {
      transform: none !important; /* Desactiva la transición vertical */
      transition: none !important; /* Desactiva las animaciones */
    }
    .chat-header {
      cursor: default; /* Quita el cursor de "manita" */
    }
    /* Oculta la lista de profesionales por defecto en móvil */
    .professional-list {
      display: block; /* Muestra la lista de profesionales por defecto */
    }
  
    /* Nuevas reglas para mostrar/ocultar la lista y el chat según "professional-selected" */
    .chat-container-mobile.professional-selected .professional-list {
      display: none; /* Oculta la lista de profesionales */
    }
    .chat-container-mobile.professional-selected .chat-messages {
      display: block; /* Muestra la sección de chat */
    }
  }