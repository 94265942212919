/* Estilos base para el formulario */
.reviwer-form-profesional {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  border-radius: 6px;
}

/* Título */
.reviwer-form-profesional__title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

/* Grupos de campos */
.reviwer-form-profesional__group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

/* Labels */
.reviwer-form-profesional__label {
  margin-bottom: 5px;
  font-weight: bold;
}

/* Select y textarea */
.reviwer-form-profesional__select,
.reviwer-form-profesional__textarea {
  padding: 10px;
  font-size: 1rem;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Botón */
.reviwer-form-profesional__button {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reviwer-form-profesional__button:hover {
  background-color: #0056b3;
}

/* Estilos responsive */
@media (max-width: 600px) {
  .reviwer-form-profesional {
    padding: 15px;
  }

  .reviwer-form-profesional__title {
    font-size: 1.3rem;
  }

  .reviwer-form-profesional__select,
  .reviwer-form-profesional__textarea {
    font-size: 0.9rem;
  }

  .reviwer-form-profesional__button {
    font-size: 0.9rem;
  }
}
