.star-rating {
  display: flex;
  align-items: center;
}

.star {
  font-size: 1.5em;
  color: #ffd700; /* Color dorado para las estrellas */
}

.full-star {
  color: #ffd700; /* Color dorado para las estrellas llenas */
}

.half-star {
  color: #ffd700; /* Color dorado para las estrellas a medias */
}

.empty-star {
  color: #ccc; /* Color gris para las estrellas vacías */
}