/* Variables CSS para colores y otros estilos */
:root {
    --reactions-bg-color: #fff;
    --reactions-border-color: #ccc;
    --reactions-border-radius: 5px;
    --reactions-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    --reaction-font-size: 1.2em;
    --reaction-margin: 2px;
    --transition-duration: 0.3s;
    --hover-opacity: 1;
    --hidden-opacity: 0;
  }
  
  /* Ocultar los botones de reacción por defecto */
  .reactions-buttons {
    display: none;
    position: absolute;
    background-color: var(--reactions-bg-color);
    border: 1px solid var(--reactions-border-color);
    border-radius: var(--reactions-border-radius);
    padding: 8px;
    box-shadow: var(--reactions-shadow);
    opacity: var(--hidden-opacity);
    transition: opacity var(--transition-duration) ease-in-out;
    z-index: 10;
  }
  
  /* Mostrar los botones de reacción cuando se pasa el cursor sobre el mensaje */
  .message:hover .reactions-buttons,
  .reactions-buttons:hover {
    display: block;
    opacity: var(--hover-opacity);
  }
  
  /* Estilo para los botones de reacción */
  .reactions-buttons button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: var(--reaction-font-size);
    margin: 0 var(--reaction-margin);
    transition: transform var(--transition-duration) ease;
  }
  
  .reactions-buttons button:hover {
    transform: scale(1.2); /* Efecto de zoom al pasar el cursor */
    color: #007bff; /* Cambiar color en hover */
  }
  
  /* Estilo para las reacciones mostradas */
  .reactions {
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px; /* Espaciado entre reacciones */
  }
  
  /* Accesibilidad y mejor manejo del foco */
  .reactions-buttons button:focus {
    outline: 2px solid #007bff;
    outline-offset: 2px;
  }
  