.star-rating {
  display: flex;
  align-items: center;
}

.star {
  color: var(--primary-color);
  margin-right: 2px;
}

.full-star {
  color: #FFD700; /* Color dorado para estrellas completas */
}

.half-star {
  color: #FFD700; /* Color dorado para media estrella */
}

.empty-star {
  color: #ccc; /* Color gris para estrellas vacías */
}