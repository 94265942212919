/* CheckboxStyles.css */

.muestra-profesional-tareas {
  display: flex;
  flex-direction: column; /* Apila los checkboxes verticalmente */
  margin-bottom: 20px;
}

.muestra-profesional-tareas label {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.muestra-profesional-tareas label:hover {
  background-color: #e9ecef;
}

.muestra-profesional-tareas input {
  display: none; /* Oculta el checkbox real */
}

.muestra-profesional-tareas input + span::before {
  content: '✔'; /* Muestra un check */
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 2px solid #007bff;
  border-radius: 4px;
  text-align: center;
  line-height: 18px;
  color: #007bff;
  font-weight: bold;
}

.task-not-selected input + span::before {
  content: '✘'; /* Muestra una X */
  color: #ccc; /* Color gris */
  text-decoration: line-through; /* Línea en la mitad */
  border-color: #ccc; /* Borde gris */
}

.ver-mas-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s ease, transform 0.2s ease;
  align-self: center; /* Centra el botón */
  margin-top: 10px;
}

.ver-mas-button:hover {
  background: #0056b3;
  transform: translateY(-2px); /* Efecto de elevación al pasar el cursor */
}