/* src/professionalprofilescript/Reservations.css */
.notification-panel {
    position: fixed;
    left: -400px; /* Inicialmente fuera de la vista */
    top: 0;
    width: 300px;
    height: 100%;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    overflow-y: auto;
    z-index: 1000;
    transition: left 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  }
  
  .notification-panel.show {
    left: 0; /* Muestra el panel */
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .profile-reservations-list {
    list-style: none;
    padding: 0;
  }
  
  .reservation-item {
    background: #f9f9f9;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .reservation-item:hover {
    background: #f1f1f1;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .reservation-item p {
    margin: 5px 0;
    font-size: 14px;
    color: #333;
  }
  
  .reservation-item p:first-child {
    font-weight: bold;
  }
  
  .reservation-accept-button {
    background: #28a745; /* Verde para aceptar */
    color: white;
    border: none;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .reservation-accept-button:hover {
    background: #218838;
  }
  
  .reservation-cancel-button {
    background: #dc3545; /* Rojo para cancelar */
    color: white;
    border: none;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .reservation-cancel-button:hover {
    background: #c82333;
  }
  
  .notification-panel p {
    text-align: center;
    font-size: 16px;
    color: #666;
  }
  
  @media (max-width: 600px) {
    .notification-panel {
      width: 100%;
      left: -100%; /* Ocultar fuera de vista */
    }
  
    .notification-panel.show {
      left: 0; /* Mostrar panel */
    }
  }
  