.reservations-container {
  padding: 20px;
}

.reservations-section {
  margin-bottom: 20px;
}

.reservations-section h2 {
  margin-bottom: 10px;
}

.reservations-section ul {
  list-style-type: none;
  padding: 0;
}

.reservations-section li {
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}
