.custom-scroll {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #007bff #f4f4f4;
    max-height: calc(100vh - 200px); /* Ajusta la altura máxima según sea necesario */
  }
  
  .custom-scroll::-webkit-scrollbar {
    width: 8px;
  }
  
  .custom-scroll::-webkit-scrollbar-track {
    background: #f4f4f4;
    border-radius: 10px;
  }
  
  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 10px;
    border: 2px solid #f4f4f4;
  }
  
  .custom-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #0056b3;
  }