#header-container {
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho disponible */
  max-width: 1567px; /* Limita el ancho máximo para que no se desborde */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  margin: 0 auto; /* Centra el contenedor en la pantalla */
  box-sizing: border-box; /* Asegura que el padding no afecte el tamaño */
}

.portada-reseñas-lista {
  position: relative;
  width: 100%; /* Asegura que ocupe todo el ancho */
  height: 800px; /* Ajusta según la altura requerida */
  overflow: hidden; /* Evita que el contenido interno se desborde */
  display: flex; /* Asegura que el contenido se alinee */
  align-items: center; /* Centra el contenido verticalmente */
  justify-content: center; /* Centra el contenido horizontalmente */
  border-radius: 30px; /* Estilo del borde */
  background: #000; /* Fondo negro como respaldo si el video no carga */
}

.portada-video {
  position: absolute; /* Necesario para que el video se ajuste */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cubre todo el contenedor sin distorsionarse */
  object-position: center; /* Asegura que el video esté centrado */
  z-index: 1; /* Asegura que el video esté detrás de los puntos de navegación */

}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.video-navigation {
  position: absolute;
  bottom: 20px; /* Ajusta la posición de los puntos de navegación */
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2; /* Asegura que los puntos de navegación estén por encima del video */
}

.nav-button {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-button.active {
  background-color: #0071E3;
}

.nav-button:hover {
  background-color: #005bb5;
}

.portada-reseñas-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}

.portada-reseñas-titulo,
.portada-reseñas-lista {
  flex: 1;
  background-color: #000;
  color: white;
  padding: 20px;
  border-radius: 30px;
  height: 800px;
  box-sizing: border-box; /* Asegura que el padding no afecte el tamaño */
  max-height: 100%; /* Hace que la altura sea relativa al espacio disponible */
  overflow: hidden; /* Evita que el contenido se desborde */
}


.portada-reseñas-titulo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  padding-left: 50px;
}

.portada-reseñas-titulo h1 {
  color: #FFF;
  font-family: Montserrat, sans-serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 97%;
  letter-spacing: -4px;
  margin-bottom: 80px; /* Añade una distancia de 20px entre el título y el botón */
}

.portada-reseñas-palabra-cambio {
  font-weight: bold;
}

.portada-reseñas-boton {
  display: flex;
  width: 500px;
  padding: 21px 11px;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #0071E3;
  color: #FFF;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -2px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: -50px; /* Sube el botón 50px más */
}

.portada-reseñas-boton:hover {
  background: #005bb5;
}


.portada-reseñas-item {
  display: flex;
  width: 100%;
  height: 127px;
  padding: 20px 22px 17px 27px;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  background: #FFF;
  border-radius: 30px;
  box-sizing: border-box;
}

.portada-reseñas-foto {
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  border-radius: 100px;
  margin-right: 15px;
}

.portada-reseñas-contenido {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.portada-reseñas-usuario {
  font-size: 1.1rem;
  margin-bottom: 5px;
  margin-left: 20px;
}

.portada-reseñas-texto {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.72px;
}

/* MEDIA QUERIES PARA DISPOSITIVOS MÓVILES */
@media (max-width: 1200px) {
  #header-container {
    width: 100%; /* Para pantallas más pequeñas, ocupamos el 100% del ancho */
  }

  .portada-reseñas-titulo h1 {
    font-size: 60px; /* Reduce el tamaño del título */
    margin-bottom: 50px; /* Menos espacio entre el título y el botón */
  }

  .portada-reseñas-boton {
    width: 400px; /* Reduce el tamaño del botón */
    font-size: 40px; /* Reduce el tamaño de la fuente del botón */
  }

  .portada-reseñas-lista {
    width: 100%; /* Asegura que ocupe el 100% del ancho */
  }

  .portada-reseñas-item {
    width: 100%; /* Hace que los items ocupen todo el ancho */
    height: auto; /* Ajusta la altura de los items */
  }

  .portada-reseñas-usuario {
    font-size: 1rem; /* Reduce el tamaño del nombre de usuario */
  }

  .portada-reseñas-texto {
    font-size: 14px; /* Reduce el tamaño del texto de los comentarios */
  }
}

@media (max-width: 768px) {
  #header-container {
    flex-direction: column; /* Cambia a una dirección vertical en pantallas más pequeñas */
    align-items: center; /* Centra los elementos */
  }

  .portada-reseñas-titulo,
  .portada-reseñas-lista {
    width: 90%; /* Reduce el ancho para dispositivos más pequeños */
    height: auto; /* Ajusta la altura */
    padding: 15px; /* Reduce el padding */
  }

  .portada-reseñas-boton {
    width: 100%; /* Hace que el botón ocupe el 100% del ancho */
    font-size: 40px; /* Ajusta el tamaño de la fuente */
    margin-top: 20px; /* Ajusta el margen */
  }

  .portada-reseñas-item {
    width: 100%; /* Asegura que cada item ocupe todo el ancho */
    padding: 15px; /* Ajusta el padding de los items */
  }
}
