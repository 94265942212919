.loader-charlotmed-container {
    @apply flex items-center justify-center h-screen bg-white;
  }
  
  .loader-charlotmed-circle {
    @apply w-12 h-12;
  }
  
  .loader-charlotmed-info {
    @apply flex flex-col gap-2 ml-4;
  }
  
  .loader-charlotmed-line-large {
    @apply animate-pulse bg-gray-300 w-28 h-5 rounded-full;
  }
  
  .loader-charlotmed-line-extra {
    @apply animate-pulse bg-gray-300 w-36 h-5 rounded-full;
  }
  
  /* Círculo giratorio de Uiverse.io por barisdogansutcu */
  .loader-charlotmed-container svg {
    width: 3.25em;
    transform-origin: center;
    animation: loader-charlotmed-rotate4 2s linear infinite;
  }
  
  .loader-charlotmed-container circle {
    fill: none;
    stroke: hsl(214, 97%, 59%);
    stroke-width: 2;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: loader-charlotmed-dash4 1.5s ease-in-out infinite;
  }
  
  @keyframes loader-charlotmed-rotate4 {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes loader-charlotmed-dash4 {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dashoffset: -125px;
    }
  }