.reservationsmyaccount {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  background: #fff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  text-align: left;
  font-family: "Inter", sans-serif;
}

.reservationsmyaccount h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #333;
}

.no-reservations {
  color: #777;
  font-size: 16px;
  font-weight: 500;
  background: #f0f0f0;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
}

.reservation-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reservation-item {
  background: #fff;
  border-radius: 12px;
  padding: 1.2rem;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid #eaeaea;
}

.reservation-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.reservation-item p {
  margin: 0;
  font-size: 14px;
  color: #555;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reservation-label {
  font-weight: 600;
  color: #333;
}

.reservation-divider {
  height: 1px;
  background: #e0e0e0;
  margin: 8px 0;
}

/* Responsividad */
@media (max-width: 768px) {
  .reservationsmyaccount {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .reservationsmyaccount {
    padding: 1rem;
  }
  .reservation-item {
    padding: 1rem;
  }
}