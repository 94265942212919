/* Contenedor de la lista de mensajes */
.message-list {
  display: flex;
  flex-direction: column;
  background-color: #ffffff; /* Fondo más similar a WhatsApp */
  padding: 5px;
  overflow-y: auto;
  max-height: 70vh;
  border: 1px solid #ffffff;
  border-radius: 10px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

/* Estilo para los mensajes */
.message {
  max-width: 75%;
  margin: 6px 0;
  padding: 10px 14px;
  border-radius: 8px 8px 8px 0; /* Bordes redondeados asimétricos */
  position: relative;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Ligera sombra para resaltar */
}

/* Mensajes enviados */
.message.sent {
  align-self: flex-end;
  background-color: #d9fdd3; /* Verde claro similar a WhatsApp */
  text-align: left; /* WhatsApp usa alineación izquierda para texto */
  border-radius: 8px 8px 0 8px; /* Bordes superiores redondeados */
}

/* Mensajes recibidos */
.message.received {
  margin-left: 5px;
  align-self: flex-start;
  background-color: #ffffff; /* Fondo blanco similar */
  text-align: left;
  border-radius: 8px 8px 8px 0; /* Bordes inferiores redondeados */
  padding: 10px 15px;
  border-radius: 10px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

/* Estilo para el texto del mensaje */
.message p {
  margin: 0;
  padding: 0;
  color: #333;
}

/* Estilo para el nombre del remitente */
.message strong {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  
  font-size: 12px;
  color: #007BFF; /* Verde oscuro para nombre del remitente */
}

/* Estilo para las imágenes adjuntas */
.message img {
  max-width: 100%;
  border-radius: 8px;
  margin-top: 5px;
}

/* Estilo para los enlaces de descarga */
.message a {
  color: #128c7e; /* Verde típico de enlaces en WhatsApp */
  text-decoration: none;
}

.message a:hover {
  text-decoration: underline;
}

/* Estilos responsivos para pantallas móviles */
@media (max-width: 768px) {
  .message-list {
    padding: 8px;
    max-height: 90vh;
  }

  .message {
    max-width: 90%;
    padding: 8px 12px;
  }

  .message.sent {
    background-color: #d9fdd3;
  }

  .message.received {
    background-color: #f8f9fa;
  }
}

/* Estilos para pantallas más grandes */
@media (min-width: 769px) {
  .message {
    font-size: 1rem;
  }
}
