/* Contenedor principal del chat */
.chat-container {
  position: fixed;
  bottom: 0;
  right: 20px;
  width: 100%;
  max-width: 500px;
  max-height: 80%;
  background-color: white;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  margin-bottom:0px;
}

/* Contenedor abierto */
.chat-container.open {
  transform: translateY(0);
}

/* Contenedor cerrado */
.chat-container:not(.open) {
  transform: translateY(calc(100% - 50px)); /* Solo muestra la cabecera */
}

/* Encabezado del chat */
.chat-header {
  display: flex; /* Flexbox para alinear contenido */
  align-items: center; /* Centra el contenido verticalmente */
  justify-content: center; /* Centra el título horizontalmente */
  background-color: #007bff; /* Fondo azul */
  color: #ffffff; /* Texto blanco */
  font-family: 'Arial', sans-serif; /* Fuente legible */
  font-size: 18px; /* Tamaño de texto */
  font-weight: bold; /* Texto en negrita */
  padding: 15px; /* Espaciado interno */
  height: 56px; /* Altura fija para consistencia */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Sombra ligera en lugar de un borde */
  box-sizing: border-box; /* Incluye el padding en el tamaño total */
  z-index: 10; /* Asegura que el encabezado esté por encima de otros elementos */
}

.chat-header:hover {
  background-color: #0056b3; /* Azul más oscuro al pasar el cursor */
}


.chat-content {
  display: flex; /* Flexbox para alinear profesional y conversación en horizontal */
  flex-direction: row; /* Elementos dispuestos en fila */
  width: 100%;
  height: 850px; /* Altura fija para ambos contenedores */
  box-sizing: border-box;
  overflow: hidden; /* Oculta cualquier contenido desbordado */
}

/* Lista de profesionales */
.professional-list {
  flex: 1; /* Ocupa todo el espacio disponible dentro de .chat-content */
  padding: 10px;
  overflow-y: auto; /* Permite scroll si el contenido excede */
  background-color: #f8f9fa; /* Fondo gris claro */
  box-sizing: border-box;
}

.chat-messages {
  flex: 1; /* Ocupa el espacio restante en el contenedor principal */
  height: 100%; /* Coincide con la altura del contenedor principal */
  background-color: white;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto; /* Scroll si hay muchos mensajes */
}

/* Estilo para los mensajes */
.message {
  max-width: 80%;
  margin: 5px 0;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
  position: relative;
  display: inline-block;
  font-size: 1em;
  line-height: 1.4;
}

/* Mensajes enviados */
.message.sent {
  align-self: flex-end;
  background-color: #dcf8c6; /* Fondo verde claro */
  text-align: right;
}

/* Mensajes recibidos */
.message.received {
  align-self: flex-start;
  background-color: #ffffff; /* Fondo blanco */
  text-align: left;
}

/* Entrada de mensajes */
.message-input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc; /* Línea divisoria */
  background-color: #fff;
}

/* Campo de entrada */
.message-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 1em;
  box-sizing: border-box;
}

/* Botón de enviar */
.send-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-button:hover {
  background-color: #0056b3;
}

/* Botón de enviar archivo */
.file-input-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.file-input-button:hover {
  background-color: #0056b3;
}

/* Contenedor de la lista de profesionales */
.professionallistuser-container {
  width: 500px; /* Ancho fijo de 500px */
  height: 100%; /* Coincide con la altura del contenedor principal */
  background-color: #f7f8fa;
  border-right: 1px solid #ccc; /* Línea divisoria entre la lista y el chat */
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box; /* Incluye el padding en las dimensiones */
  overflow-y: auto; /* Scroll si hay demasiados profesionales */
}


/* Encabezado del componente */
.professionallistuser-header {
  font-family: 'Montserrat', sans-serif; /* Familia de fuentes */
  font-size: 1.2em;
  font-weight: bold;
  color: #333; /* Texto oscuro */
  margin-bottom: 10px; /* Espaciado inferior */
}

/* Contenedor de la lista */
.professionallistuser-list {
  flex: 1; /* Ocupa el espacio restante del contenedor */
  overflow-y: auto; /* Permite scroll si la lista excede el tamaño */
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Espaciado entre elementos */
}

/* Elementos individuales */
.professionallistuser-item {
  display: flex;
  align-items: center;
  padding: 10px; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados */
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  color: #333; /* Color del texto */
  cursor: pointer; /* Indicador de clic */
  transition: background-color 0.3s ease;
  background-color: white; /* Fondo blanco */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Sombra ligera */
}

/* Hover y selección */
.professionallistuser-item:hover {
  background-color: #e6f7ff; /* Fondo azul claro al pasar el mouse */
}

.professionallistuser-item.selected {
  background-color: #d6eaff; /* Fondo para elemento seleccionado */
  font-weight: bold;
}

/* Imagen del profesional */
.professionallistuser-item img {
  border-radius: 50%; /* Imagen circular */
  width: 50px; /* Ancho fijo */
  height: 50px; /* Altura fija */
  margin-right: 10px; /* Espaciado derecho */
}

/* Contenedor de texto */
.professionallistuser-item .text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Nombre del profesional */
.professionallistuser-item .name {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Último mensaje */
.professionallistuser-item .last-message {
  font-size: 0.9em;
  color: #666; /* Texto gris claro */
}

.chat-controls {
  display: flex; /* Flexbox para organizar elementos */
  align-items: center; /* Centra verticalmente los elementos */
  justify-content: space-between; /* Espaciado entre los elementos */
  padding:40px 10px 0px 20px;
  background-color: #ffffff; /* Fondo gris claro */
  box-sizing: border-box; /* Incluye el padding en el tamaño total */
  width: 100%; /* Ocupa todo el ancho disponible */
  height: 30px;
}

.chat-controls button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px; /* Espaciado interno */
  font-size: 14px; /* Tamaño de texto */
  font-weight: bold;
  color: #007bff; /* Texto azul */
  background-color: transparent; /* Fondo transparente */
  border: none; /* Sin bordes */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.chat-controls button:hover {
  color: #0056b3; /* Azul más oscuro al pasar el cursor */
  background-color: #e6f7ff; /* Fondo claro al pasar el cursor */
}

.chat-controls button:focus {
  outline: 2px solid #007bff; /* Borde de enfoque */
  outline-offset: 2px;
}


.back-button {
  display: inline-flex; /* Alinea el contenido del botón */
  align-items: center; /* Centra el texto verticalmente */
  padding: 15px 15px; /* Espaciado interno */
  font-family: 'Arial', sans-serif; /* Fuente legible */
  font-size: 16px; /* Tamaño de texto más claro */
  font-weight: bold; /* Resalta el texto */
  color: #007bff; /* Azul para el texto */
  background-color: transparent; /* Fondo transparente */
  border: none; /* Sin bordes */
  cursor: pointer; /* Cursor de mano */
  text-decoration: none; /* Sin subrayado */
  transition: color 0.3s ease, background-color 0.3s ease; /* Transición suave */
  border-radius: 5px; /* Bordes ligeramente redondeados */
}

.back-button:hover {
  color: #0056b3; /* Azul más oscuro al pasar el cursor */
  background-color: #e6f7ff; /* Fondo claro para resaltar */
}

.back-button:focus {
  outline: 2px solid #007bff; /* Resalta el borde para accesibilidad */
  outline-offset: 2px; /* Desplaza el borde del texto */
}

.back-button svg {
  margin-right: 5px; /* Espacio entre el ícono y el texto */
  fill: #007bff; /* Color del ícono */
  transition: fill 0.3s ease; /* Transición suave para el color del ícono */
}

.back-button:hover svg {
  fill: #0056b3; /* Cambia el color del ícono al pasar el cursor */
}

.message.sent {
  align-self: flex-end; /* Alinea el mensaje a la derecha */
  background-color: #dcf8c6; /* Fondo verde claro */
  color: #333; /* Texto oscuro */
  border-radius: 10px; /* Bordes redondeados */
  padding: 5px 15px; /* Espaciado interno */
  margin: 5px 0; /* Espaciado entre mensajes */
  max-width: 80%; /* Limita el ancho del mensaje */
  word-wrap: break-word; /* Permite que las palabras largas se corten */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Sombra ligera */
  position: relative; /* Para posicionar los tres puntos */
  font-size: 14px;
  line-height: 1.5; /* Espaciado entre líneas */
  display: flex; /* Cambiado a flex para alinear contenido */
  flex-direction: column; /* Alinea el contenido en columna */
}

/* Texto principal */
.message.sent p {
  margin: 0;
  font-size: 14px;
  color: #333; /* Texto principal */
  font-weight: normal;
}

/* Enlace */
.message.sent a {
  font-size: 14px;
  color: #007bff;
  text-decoration: underline;
  margin-top: 5px; /* Espaciado debajo del texto */
}


/* Reajustar padding para evitar cuadros sobrantes */
.message.sent .reactions,
.message.sent .extra-elements {
  display: none; /* Oculta elementos extra si no son necesarios */
}


/* Vista responsiva */
@media (max-width: 1024px) {
  .chat-container {
    max-width: 400px;
    right: 10px;
  }

  .message {
    max-width: 85%;
    font-size: 0.95em;
  }
}

@media (max-width: 768px) {
  .chat-container {
    right: 0;
    width: 100%;
    max-width: none;
    height: 100vh;
    border-radius: 0;
  }

  .chat-header {
    font-size: 1.1em;
    padding: 10px;
  }

  .chat-messages {
    padding: 10px;
  }

  .message {
    max-width: 90%;
    font-size: 0.9em;
  }

  .message-input-container {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .chat-header {
    font-size: 1em;
  }

  .message {
    max-width: 95%;
    font-size: 0.85em;
  }

  .send-button,
  .file-input-button {
    padding: 8px 15px;
    font-size: 0.85em;
  }
  
}
