/* src/ReservationForm/Button.css */

.view-professionals-button {
  display: flex;
  height: 95px;
  padding: 21px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #0071E3; /* Color de fondo */
  color: #FFF; /* Color del texto */
  border: none;
  border-radius: 50px; /* Bordes redondeados */
  font-family: Montserrat, sans-serif;
  font-size: 50px; /* Tamaño de fuente */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -2px;
  text-align: center;
  cursor: pointer;
}

.view-professionals-button:hover {
  background-color: #FFF; /* Color de fondo en hover */
  color: #0071E3; /* Color del texto en hover */
}