.my-account-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.dashboard-content {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  width: 100%;
}

.profile-section {
  grid-column: span 4;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personal-data-section {
  grid-column: span 8;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.reservations-section {
  grid-column: span 12;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.logout-section {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.logout-section button {
  background-color: #0071E3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-section button:hover {
  background-color: #005bb5;
}

/* Responsividad */

/* Pantallas Medianas (≤768px) */
@media (max-width: 768px) {
  
  
  .dashboard-content {
    grid-template-columns: repeat(6, 1fr);
  }

  .profile-section {
    grid-column: span 6;
  }

  .personal-data-section {
    grid-column: span 6;
  }

  .reservations-section {
    grid-column: span 6;
  }
}

/* Pantallas Pequeñas (≤480px) */
@media (max-width: 480px) {
  .dashboard-content {
    grid-template-columns: repeat(1, 1fr);
  }

  .profile-section,
  .personal-data-section,
  .reservations-section {
    grid-column: span 1;
  }
}

/* Ocultar el botón de cerrar sesión en pantallas más grandes */
@media (min-width: 769px) {
  .logout-section {
    display: none;
  }
}