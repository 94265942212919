/* Botón de retroceso */
.back-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 1.2em;
    margin-bottom: 10px;
    transition: color 0.3s ease;
  }
  
  /* Botón de retroceso al pasar el cursor */
  .back-button:hover {
    color: #0056b3;
  }