/* Reinicio básico para evitar inconsistencias */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Estilo global del body */
body {
  background-color: #ffffff; /* Fondo blanco */
  font-family: Arial, sans-serif; /* Fuente por defecto */
}

/* Elementos de chat para escritorio */
.desktop-chat {
  display: none;
}

@media (min-width: 768px) {
  .desktop-chat {
    display: block;
  }
}

/* Elementos de chat para móvil */
.mobile-chat {
  display: block;
}

@media (min-width: 768px) {
  .mobile-chat {
    display: none;
  }
}
