/* src/professionalprofilescript/ProfilePicture.css */

/* Contenedor de la Imagen de Perfil */
.profile-picture-container {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto; /* Centra horizontalmente */
  text-align: center;
}

/* Imagen de Perfil */
.profile-picture {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid #ccc; /* Opcional: bordes alrededor de la imagen */
}

/* Botón de Subida */
.profile-upload-button {
  display: block; /* Permite centrar el botón bajo la imagen */
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 15px auto 0 auto; /* Margen superior e inferior con centrado horizontal */
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s ease, transform 0.2s ease;
}

.profile-upload-button:hover {
  background: #0056b3;
  transform: translateY(-2px); /* Efecto de elevación al pasar el cursor */
}

/* Campana de Notificación */
.profile-notification-bell {
  font-size: 24px;
  color: #007bff;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  background: white;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, color 0.3s ease;
}

.profile-notification-bell:hover {
  transform: rotate(15deg);
  color: #0056b3;
}

/* Dropdown Menu */
.dropdown-menu {
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
}

/* src/ProfessionalProfileStyles/ProfileSummary.css */

.profile-summary {
  text-align: center;
  margin-bottom: 20px;
  width: 462px;
  height: 541px;
  flex-shrink: 0;
  border-radius: 43px;
  background: #D9D9D9;
}

.profile-image-container {
  position: relative;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
  cursor: pointer; /* Añadido cursor de puntero */
}

.profile-summary p {
  margin: 5px 0;
}

/* Clase para el contenedor del resumen profesional */

/* Clase para el icono de edición */
.edit-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 20px;
  color: #007bff;
  cursor: pointer;
  background: white;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, color 0.3s ease;
}

.edit-icon:hover {
  transform: rotate(15deg);
  color: #0056b3;
}