.displaynamemyaccount {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 25px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.displaynamemyaccount:hover {
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.15);
}

.name-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.name-input:focus {
  border-color: #007aff;
  outline: none;
}

.submit-btn {
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  background: #007aff;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;
}

.submit-btn:hover {
  background: #005ecb;
}

/* Responsividad */
@media (max-width: 768px) {
  .displaynamemyaccount {
    padding: 20px;
  }
  .name-input {
    font-size: 14px;
    padding: 10px;
  }
  .submit-btn {
    font-size: 14px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .displaynamemyaccount {
    padding: 15px;
  }
  .name-input {
    font-size: 12px;
    padding: 8px;
  }
  .submit-btn {
    font-size: 12px;
    padding: 8px;
  }
}