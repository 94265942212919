.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-sizing: border-box;
}

/* Importa la fuente Montserrat desde Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

.services-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Distribuye las tarjetas dinámicamente */
  gap: 1.5rem;
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho */
  max-width: 1530px; /* Limita el ancho máximo del grid */
}

.service-card {
  height: 400px;
  background-color: #000000; /* Fondo rosado */
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: scale(1.010);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.service-card-link {
  text-decoration: none;
  color: #4a148c; /* Texto en rosado oscuro */
  text-align: center;
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
}

.service-image {
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  transition: opacity 0.3s ease;
}

.service-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 60px;
  height: 60px;
  transition: opacity 0.3s ease;
}

.service-overlay {
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 1); /* Fondo negro total */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: left 0.3s ease;
}

.service-card:hover .service-overlay {
  left: 0;
}

.service-card:hover .service-image,
.service-card:hover .service-icon {
  opacity: 0.3;
}

.service-card-title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  word-wrap: break-word; /* Permite que las palabras largas se dividan */
}

.service-description {
  font-size: 1.2rem;
  font-family: 'Montserrat', sans-serif;
}