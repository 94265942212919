/* src/professionalprofilescript/Gallery.css */

/* Contenedor de la Galería */
.muestra-profesional-galeria {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Mayor separación entre las imágenes */
  padding: 20px;
  background-color: #f9f9f9; /* Fondo claro para la sección */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Imágenes de la Galería */
.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Mayor separación entre las imágenes */
  justify-content: center; /* Centra las imágenes horizontalmente */
}

.gallery-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transiciones suaves */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra sutil para resaltar las imágenes */
}

.gallery-image:hover {
  transform: scale(1.1); /* Efecto de zoom al pasar el cursor */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Responsividad */
@media (max-width: 1024px) {
  .gallery-image {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 768px) {
  .gallery-image {
    width: 100px;
    height: 100px;
  }

  .gallery {
    gap: 10px; /* Reduce la separación en pantallas más pequeñas */
  }
}

@media (max-width: 480px) {
  .gallery-image {
    width: 80px;
    height: 80px;
  }

  .gallery {
    padding: 10px;
  }
}
