/* Contenedor de entrada de mensajes */
.message-input-bar {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    border-top: 1px solid #ffffff;
    background-color: #fff;
    margin-bottom: 0; /* Mueve el componente 20px hacia arriba */
    transition: transform 0.3s ease; /* Transición suave para el movimiento */
    
  }
  

  /* Estilo para el campo de entrada de mensajes */
  .message-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-right: 10px;
  }
  
  /* Estilo para el botón de enviar */
  .send-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Estilo para el botón de enviar al pasar el cursor */
  .send-button:hover {
    background-color: #0056b3;
  }
  
  /* Estilo para el botón de adjuntar archivos */
  .file-input-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s ease;
  }
  
  /* Estilo para el botón de adjuntar archivos al pasar el cursor */
  .file-input-button:hover {
    background-color: #0056b3;
  }
