/* Estilos base para todas las resoluciones */
.professional-detail-container {
  display: grid;
  grid-template-columns: 1fr; /* Una columna por defecto (móvil) */
  gap: 10px; /* Menos espacio en móviles */
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px; /* Menos padding en móviles */
  align-items: flex-start; /* Importante para que el sticky funcione */
}

/* Ajustes en móviles para reducir espacios excesivos */
.left-column, .right-column {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Espacio reducido en móviles */
}

/* Perfil, galería y reseñas */
.professional-detail-profile,
.professional-detail-gallery,
.professional-detail-reviews,
.review-form {
  background-color: #fff;
  border-radius: 10px; /* Bordes más pequeños */
  padding: 12px; /* Ajuste para móviles */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Menos sombra para que no se vea tan voluminoso */
}

/* Ajustes para mejorar la alineación de los textos en móviles */
.review-form label {
  font-size: 14px; /* Tamaño de texto más compacto */
}

/* Mejor estructura en móviles */
.review-form textarea, 
.review-form select,
.review-form input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 6px;
}

/* Ajuste del botón */
.review-form button {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  text-align: center;
}

/* Mejoras para pantallas de 768px a 1024px (Tablet) */
@media (min-width: 768px) and (max-width: 1024px) {
  .professional-detail-container {
    grid-template-columns: 2fr 1fr; /* Dos columnas con proporción 2fr 1fr */
    gap: 20px; /* Espacio entre columnas */
  }

  .right-column {
    position: relative; /* No sticky en tablet para mejor manejo */
    top: 0;
  }
}

/* Ajustes en pantallas grandes para mejorar la disposición */
@media (min-width: 1024px) {
  .professional-detail-container {
    grid-template-columns: 3fr 1fr; /* Dos columnas con proporción 3fr 1fr */
    gap: 30px; /* Espacio entre columnas */
    align-items: flex-start; /* Importante para que el sticky funcione */
  }

  .right-column {
    position: sticky;
    top: 20px; /* Mantener fijo al hacer scroll */
  }

  .mobile-reservation-button {
    display: none;
  }

}

/* Ajustes para pantallas muy pequeñas (menos de 480px) */
@media (max-width: 480px) {
  .professional-detail-container {
    width: 100%;
  }

  .review-form textarea, 
  .review-form select,
  .review-form input {
    font-size: 12px; /* Texto más pequeño en pantallas pequeñas */
  }

  .review-form button {
    padding: 10px;
    font-size: 12px;
  }

  
}
