/* src/ConfigPanel.css */
.config-panel {
  position: fixed;
  right: -300px; /* Inicialmente fuera de la vista */
  top: 0;
  width: 300px;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px;
  overflow-y: auto;
  z-index: 1000;
  transition: right 0.3s ease-in-out; /* Transición suave */
}

.config-panel.open {
  right: 0; /* Mueve el panel a la vista */
}