/* Estilo para el estado de lectura */
.read-status {
  font-size: 0.6rem; /* Tamaño reducido para los iconos de visto */
  margin-top: 5px;
  align-self: flex-end;
}

.read-status.read {
  color: #ADD8E6; /* Azul muy clarito para los mensajes leídos */
}

.read-status.unread {
  color: gray; /* Color gris para los mensajes no leídos */
}