/* ProfessionalReservationForm.css */
:root {
    --primary-color: #f8abff;
    --secondary-color: #f5f5f5;
    --accent-color: #000000;
    --font-family: 'Inter', sans-serif;
    --border-radius: 12px;
    --padding: 16px;
  }
  
  body {
    font-family: var(--font-family);
    background-color: var(--secondary-color);
    margin: 0;
    padding: 0;
  }
  
  form {
    display: grid;
    gap: var(--padding);
    background: #fff;
    padding: var(--padding);
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    max-width: 600px;
    margin: 40px auto;
  }
  
  .form-pay-charlotmed-professional-info {
    background: var(--secondary-color);
    padding: var(--padding);
    border-radius: var(--border-radius);
  }
  
  input, textarea, button {
    padding: 12px;
    border-radius: var(--border-radius);
    border: 1px solid var(--accent-color);
    font-size: 16px;
  }
  
  input:focus, textarea:focus {
    outline: 2px solid var(--primary-color);
  }
  
  .form-pay-charlotmed-total {
    font-weight: bold;
  }
  
  button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #1f1f1f;
  }
  
  .form-pay-charlotmed-error-message {
    color: red;
  }
  
  /* Contenedor principal de los pickers (flechas + contenedor con items) */
  .form-pay-charlotmed-horizontal-date-picker,
  .form-pay-charlotmed-horizontal-time-picker {
    /* Ajusta el ancho deseado */
    max-width: 350px;
    position: relative;
  }
  
  /* Contenedor con items (fechas u horas) */
  .form-pay-charlotmed-date-items,
  .form-pay-charlotmed-time-items {
    display: flex;
    gap: 5px;               /* Espacio entre ítems */
    padding: 8px;           /* Espacio interno */
    margin: 0 8px;          /* Espacio externo con el borde del contenedor */
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    cursor: grab;           /* Indica que se puede arrastrar */
  }
  
  .form-pay-charlotmed-date-items.form-pay-charlotmed-dragging,
  .form-pay-charlotmed-time-items.form-pay-charlotmed-dragging {
    cursor: grabbing;       /* Cambia cursor mientras se arrastra */
  }
  
  /* Ítems (fechas u horas) */
  .form-pay-charlotmed-date-item,
  .form-pay-charlotmed-time-item {
    flex: 0 0 auto;
    background: var(--secondary-color);
    padding: 10px 14px;
    border-radius: var(--border-radius);
    transition: background 0.3s;
    user-select: none;      /* Evita que se seleccione texto al arrastrar */
  }
  
  /* Ítem seleccionado */
  .form-pay-charlotmed-date-item.form-pay-charlotmed-selected,
  .form-pay-charlotmed-time-item.form-pay-charlotmed-selected {
    background: var(--primary-color);
    color: #fff;
  }
  
  /* Estilos del scrollbar */
  .form-pay-charlotmed-date-items::-webkit-scrollbar,
  .form-pay-charlotmed-time-items::-webkit-scrollbar {
    height: 8px; /* Altura de la barra */
  }
  .form-pay-charlotmed-date-items::-webkit-scrollbar-track,
  .form-pay-charlotmed-time-items::-webkit-scrollbar-track {
    background: transparent;
  }
  .form-pay-charlotmed-date-items::-webkit-scrollbar-thumb,
  .form-pay-charlotmed-time-items::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 4px;
  }
  
  /* Donde se ubican los ítems (fechas u horas) */
  .form-pay-charlotmed-horizontal-date-picker .form-pay-charlotmed-date-items,
  .form-pay-charlotmed-horizontal-time-picker .form-pay-charlotmed-time-items {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;           /* Desplazamiento suave */
    -webkit-overflow-scrolling: touch; /* Soporte en iOS */
    scrollbar-width: thin;             /* Estilo de scroll en Firefox */
    scrollbar-color: var(--primary-color) transparent; 
    max-width: 550px; 
  }
  
  /* Estilos de scrollbar en navegadores basados en WebKit (Chrome, Safari, Edge Chromium) */
  .form-pay-charlotmed-horizontal-date-picker .form-pay-charlotmed-date-items::-webkit-scrollbar,
  .form-pay-charlotmed-horizontal-time-picker .form-pay-charlotmed-time-items::-webkit-scrollbar {
    height: 8px;             /* Alto del scrollbar horizontal */
    background: transparent; /* Fondo transparente para el canal */
  }
  
  .form-pay-charlotmed-horizontal-date-picker .form-pay-charlotmed-date-items::-webkit-scrollbar-track,
  .form-pay-charlotmed-horizontal-time-picker .form-pay-charlotmed-time-items::-webkit-scrollbar-track {
    background: transparent; /* Canal transparente */
  }
  
  .form-pay-charlotmed-horizontal-date-picker .form-pay-charlotmed-date-items::-webkit-scrollbar-thumb,
  .form-pay-charlotmed-horizontal-time-picker .form-pay-charlotmed-time-items::-webkit-scrollbar-thumb {
    background-color: var(--primary-color); /* Color del "pulgar" */
    border-radius: 8px;                     /* Bordes redondeados */
    border: 2px solid transparent;          /* Pequeño espacio para que parezca flotante */
  }
  
  .form-pay-charlotmed-horizontal-date-picker .form-pay-charlotmed-date-items::-webkit-scrollbar-thumb:hover,
  .form-pay-charlotmed-horizontal-time-picker .form-pay-charlotmed-time-items::-webkit-scrollbar-thumb:hover {
    background-color: #222; /* Color al hacer hover */
  }
  
  /* Ocultamos los botones de la barra de scroll (flechas nativas del scrollbar) */
  .form-pay-charlotmed-horizontal-date-picker .form-pay-charlotmed-date-items::-webkit-scrollbar-button,
  .form-pay-charlotmed-horizontal-time-picker .form-pay-charlotmed-time-items::-webkit-scrollbar-button {
    display: none;
  }
  
  /* Ítems (fechas u horas) */
  .form-pay-charlotmed-horizontal-date-picker .form-pay-charlotmed-date-item, 
  .form-pay-charlotmed-horizontal-time-picker .form-pay-charlotmed-time-item {
    flex: 0 0 auto;
    margin: 0 4px;
    padding: 6px 10px;
    background: var(--secondary-color);
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .form-pay-charlotmed-horizontal-date-picker .form-pay-charlotmed-date-item.form-pay-charlotmed-selected, 
  .form-pay-charlotmed-horizontal-time-picker .form-pay-charlotmed-time-item.form-pay-charlotmed-selected {
    background: var(--primary-color);
    color: white;
  }
  
  /* Flechas para scroll manual */
  button.form-pay-charlotmed-prev, 
  button.form-pay-charlotmed-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: var(--primary-color);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  button.form-pay-charlotmed-prev {
    left: 0;
  }
  
  button.form-pay-charlotmed-next {
    right: 0;
  }
  
  button.form-pay-charlotmed-prev:hover, 
  button.form-pay-charlotmed-next:hover {
    background: #1f1f1f;
  }
  
  /* Media query para pantallas de 600px o menos */
  @media (max-width: 600px) {
    /* Ajuste de padding del form */
    form {
      max-width: auto;
      box-shadow: none;
      padding: 0%;

    }
  
    /* Ajuste de tamaño para pickers */
    .form-pay-charlotmed-horizontal-date-picker,
    .form-pay-charlotmed-horizontal-time-picker {
      margin: 0 auto;
    }
  
    .form-pay-charlotmed-date-items,
    .form-pay-charlotmed-time-items {
      margin: 0 4px;
      padding: 6px;
      width: auto;
      gap: 4px;
    }
  
    .form-pay-charlotmed-date-item,
    .form-pay-charlotmed-time-item {
      padding: 6px 8px;
      font-size: 14px;
    }

    
  input, textarea, button {
    padding: 12px;
    border-radius: var(--border-radius);
    border: 1px solid var(--accent-color);
    font-size: 16px;
    max-width: auto;
  }

  /* Donde se ubican los ítems (fechas u horas) */
  .form-pay-charlotmed-horizontal-date-picker .form-pay-charlotmed-date-items,
  .form-pay-charlotmed-horizontal-time-picker .form-pay-charlotmed-time-items {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;           /* Desplazamiento suave */
    -webkit-overflow-scrolling: touch; /* Soporte en iOS */
    scrollbar-width: thin;             /* Estilo de scroll en Firefox */
    scrollbar-color: var(--primary-color) transparent; 
    max-width: auto; 
  }
  
  }
