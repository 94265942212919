.marquee-container {
  width: 100%;
  height: 100px; /* Ajusta la altura del contenedor */
  overflow: hidden;
  background-color: #f0f0f0; /* Fondo gris claro */
  padding: 1rem 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marquee {
  display: flex;
  white-space: nowrap;
  animation: marquee 15s linear infinite;
  position: absolute;
}

.marquee img {
  height: 50px; /* Ajusta el tamaño de los logos según sea necesario */
  margin: 0 20px; /* Espacio entre los logos */
  object-fit: cover; /* Asegura que las imágenes cubran el área asignada */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}