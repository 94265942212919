.profile-picture-wrapper-myaccountprofile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.profile-picture-wrapper-myaccountprofile:hover {
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.15);
}

.profile-picture-myaccountprofile {
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #e0e0e0;
  transition: transform 0.3s ease, border-color 0.3s;
}

.profile-picture-myaccountprofile:hover {
  transform: scale(1.05);
  border-color: #007aff;
}

.file-input-myaccountprofile {
  display: none;
}

.profile-actions-myaccountprofile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.submit-btn-myaccountprofile,
.delete-btn-myaccountprofile {
  width: 100%;
  max-width: 300px;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;
}

.submit-btn-myaccountprofile {
  background: #007aff;
  color: #fff;
}

.submit-btn-myaccountprofile:hover {
  background: #005ecb;
}

.delete-btn-myaccountprofile {
  background: #ff3b30;
  color: #fff;
}

.delete-btn-myaccountprofile:hover {
  background: #d32f2f;
}

.edit-profile-link-myaccountprofile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #f0f0f0;
  text-decoration: none;
  transition: background 0.3s;
}

.edit-profile-link-myaccountprofile:hover {
  background: #d6d6d6;
}

.edit-icon-myaccountprofile {
  font-size: 20px;
  color: #555;
}

.message-myaccountprofile {
  font-size: 14px;
  color: #555;
  text-align: center;
}

/* Responsividad */
@media (max-width: 768px) {
  .profile-picture-wrapper-myaccountprofile {
    max-width: 400px;
  }
  .profile-picture-myaccountprofile {
    width: 110px;
    height: 110px;
  }
  .submit-btn-myaccountprofile,
  .delete-btn-myaccountprofile {
    font-size: 14px;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .profile-picture-myaccountprofile {
    width: 80px;
    height: 80px;
  }
  .submit-btn-myaccountprofile,
  .delete-btn-myaccountprofile {
    font-size: 12px;
    padding: 8px;
  }
}